import React from 'react';
import PropTypes from 'prop-types';

import SEO from '../components/SEO';
import MainLayout from '../layouts/MainLayout';

import Common from '../../contents/images/windows/COMMUN-ACCESSOIRES-COMMUNS-AUX-FENETRES-PORTES-FENETRES-ET-BAIES.svg';
import allAccessories from '../../constants/data/accessories';

function WindowAccessoriesTemplate({
    location,
    pageContext: { material, windowType },
}) {
    const getSVGComponent = (acc = false) => {
        const svgBase = allAccessories[material][windowType];
        const svg = acc ? svgBase.acc : svgBase.p;
        const Component = svg;
        return <Component id={Math.random()} />;
    };

    return (
        <MainLayout
            headerOptions={{
                backButton: true,
            }}
        >
            <SEO
                title='Brico App'
                location={location}
                description='Brico App'
            />
            <div className='content accessories container'>
                {/* <div className='page-title'>
                    Nos fenêtres sont équipées d’un{' '}
                    <span className='txt-red'>cadre universel</span>
                    <br />
                    qui s’adapte à tous les types de pose :
                </div>
                <div className='table-bloc'>
                    <div className='img-container'>
                        <Image
                            alt='table'
                            {...data.accessories.childImageSharp}
                        />
                    </div>
                </div> */}
                {allAccessories[material][windowType].acc !== null && (
                    <>
                        <div className='page-title'>
                            Dimensions et tarifs des{' '}
                            <span className='txt-red'>
                                accessoires pour fenêtres en{' '}
                                {material.toUpperCase()}
                            </span>
                        </div>
                        <div className='table-bloc'>
                            <div className='img-container'>
                                {/* <Image alt='table' {...data.table1.childImageSharp} /> */}
                                {getSVGComponent(true)}
                                {/* <img
                                    src={
                                        allAccessories[material][windowType].acc
                                    }
                                    alt='test'
                                /> */}
                            </div>
                        </div>
                    </>
                )}

                {allAccessories[material][windowType].p !== null && (
                    <>
                        <div className='page-title'>
                            Dimensions et tarifs des{' '}
                            <span className='txt-red'>
                                poignées pour fenêtres en{' '}
                                {material.toUpperCase()}
                            </span>
                        </div>
                        <div className='table-bloc'>
                            <div
                                className='img-container'
                                style={{ marginBottom: '30px' }}
                            >
                                {/* <img
                                    src={allAccessories[material][windowType].p}
                                    alt='test'
                                /> */}
                                {getSVGComponent()}
                            </div>
                        </div>
                    </>
                )}
                <div className='page-title'>
                    Dimensions et tarifs des{' '}
                    <span className='txt-red'>accessoires communs</span>
                </div>
                <div className='table-bloc'>
                    <div className='img-container'>
                        {/* <img src={common} alt='test' /> */}
                        <Common />
                    </div>
                </div>
            </div>
        </MainLayout>
    );
}

WindowAccessoriesTemplate.propTypes = {
    location: PropTypes.object.isRequired,
    pageContext: PropTypes.shape({
        label: PropTypes.string.isRequired,
        material: PropTypes.string.isRequired,
        windowType: PropTypes.string.isRequired,
    }).isRequired,
};

export default WindowAccessoriesTemplate;
